import { useContext } from 'react'
import { useViewModel } from '@lib/view_context/view-model'
import { SettingsContext } from '@views/settings/settings.context'
import { LocationContext } from '@prospective/process-router-react'
import { LocaleContext } from '@lib/i18n/localization_provider.jsx'
import { usePluginFeatures } from '@modules/plugins/plugin_manager_react_utils.jsx'
import { theme, Card, Typography } from 'antd'
import { WindowLockIcon, OfficeBuildingIcon, ReceiptIcon, CrossReferenceIcon } from '@assets/icons/icons'
import { ErrorAlert } from '@components/elements/alerts/alerts.jsx'
import Password from '@views/settings/password/password.jsx'
import ReportList from '@views/settings/reports/report_list'
import Notifications from '@views/settings/notifications/notifications'
import ReportEditor from '@views/settings/reports/report_editor'
import CompanyInfo from '@views/settings/company_info/company_info.view'
import TasksStatus from '@components/elements/tasks_status/tasks_status.jsx'
import '@views/settings/settings.scss'

export default function Settings({ className = '', ...props }) {
    const { token } = theme.useToken()
    const { locale } = useContext(LocaleContext)
    const viewModel = useViewModel(SettingsContext)
    const location = useContext(LocationContext)
    const features = usePluginFeatures()
    const hasPermissionError = viewModel.errors.noReadPermissionsError

    const defaultMenuItems = []

    if (viewModel?.password?.visible)
        defaultMenuItems.push({
            key: 'settings/password',
            label: viewModel.password.label,
            onTrigger: viewModel.password.onTrigger,
            icon: <WindowLockIcon />,
        })
    if (viewModel?.reports?.visible)
        defaultMenuItems.push({
            key: 'settings/reports',
            label: viewModel.reports.label,
            onTrigger: viewModel.reports.onTrigger,
            icon: <ReceiptIcon />,
        })
    if (viewModel?.companyInfo?.visible) {
        defaultMenuItems.push({
            key: 'settings/company-info',
            label: viewModel.companyInfo.label,
            onTrigger: viewModel.companyInfo.onTrigger,
            icon: <OfficeBuildingIcon />,
        })
    }
    if (viewModel?.notifications?.visible)
        defaultMenuItems.push({
            key: 'settings/notifications',
            label: viewModel.notifications.label,
            onTrigger: viewModel.notifications.onTrigger,
            icon: <CrossReferenceIcon />,
        })
    if (viewModel?.invoices?.visible) {
        defaultMenuItems.push({
            key: 'settings/invoices',
            label: viewModel.invoices.label,
            onTrigger: viewModel.invoices.onTrigger,
            icon: <ReceiptIcon />,
        })
    }

    const menuItems = features.settings?.menuItems?.injectWithOriginal(defaultMenuItems, { viewModel }) || []
    const customerSettingsMenuItems =
        features?.settings?.customerSettingsMenuItems?.injectWithOriginal([], { viewModel }) || []

    const onMenuClick = item => {
        const menuItem = [...menuItems, ...customerSettingsMenuItems].find(menuItem => menuItem.key === item.key)
        if (menuItem?.onTrigger) menuItem.onTrigger()
    }

    const menuItemStyles = {
        borderRadius: token.borderRadius,
        backgroundColor: token.colorBgContainer,
        padding: token.padding,
        '--element-box-shadow': token.boxShadow,
    }

    const containerStyle = {
        backgroundColor: token.colorBgElevated,
        borderRadius: token.borderRadius,
    }

    return (
        <div className={`settings ${className}`} {...props}>
            {location.key === 'settings' ? (
                !hasPermissionError ? (
                    <div className="settings__wrap">
                        <div className="settings__wrap-item">
                            <h1>{locale('settings')}</h1>
                            <div className="settings__menu-items">
                                {menuItems?.map(item => (
                                    <Card
                                        key={item.key}
                                        className="settings__card"
                                        style={menuItemStyles}
                                        onClick={onMenuClick.bind(null, item)}
                                    >
                                        <div className="settings__card-content">
                                            <Typography.Title level={5}>{item.label}</Typography.Title>
                                            {item.icon}
                                        </div>
                                    </Card>
                                ))}
                            </div>
                        </div>
                        {customerSettingsMenuItems.length > 0 && (
                            <div className="settings__wrap-item">
                                <h1>{locale('settings.customerSettings')}</h1>
                                <div className="settings__menu-items">
                                    {customerSettingsMenuItems?.map(item => (
                                        <Card
                                            key={item.key}
                                            className="settings__card"
                                            style={menuItemStyles}
                                            onClick={onMenuClick.bind(null, item)}
                                        >
                                            <div className="settings__card-content">
                                                <Typography.Title level={5}>{item.label}</Typography.Title>
                                                {item.icon}
                                            </div>
                                        </Card>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    <ErrorAlert error={viewModel.errors.noReadPermissionsError} closable={false} />
                )
            ) : (
                <div style={containerStyle}>
                    {location.key === 'settings/password' && <Password />}
                    {location.key === 'settings/company-info' && <CompanyInfo />}
                    {location.key === 'settings/reports' && <ReportList />}
                    {location.key === 'settings/reports/report' && <ReportEditor />}
                    {location.key === 'settings/notifications' && <Notifications />}
                    {features.settings?.views?.inject()}
                </div>
            )}
            <TasksStatus className="centered-content" tasks={viewModel.tasks.dictionary} />
        </div>
    )
}
