import { BusinessObject, DateValue, Value } from '@structures/business_object.js'

const parseDate = dtoValue => (dtoValue ? new Date(dtoValue) : undefined)

const OrderDescriptor = {
    id: Value,
    state: Value,
    runtime: {
        from: DateValue,
        to: DateValue,
    },
    publications: Value,
    createdAt: DateValue,
    billingReceiverId: Value,
    // This is only needed for the ATS
    applicationContactId: Value,
    pits: Value,
    termsAndConditions: Value,
    voucher: Value,
}

const fromDTO = dto =>
    Order({
        id: dto.order_id,
        state: dto.order_state,
        runtime: {
            from: parseDate(dto.runtime?.from),
            to: parseDate(dto.runtime?.to),
        },
        publications: dto.publications,
        createdAt: parseDate(dto.created_at),
        billingReceiverId: dto.billing_receiver,
        applicationContactId: dto.application_contact_id,
        voucher: dto.voucher,
    })

/*const toDTO = order => ({
    order_id: order.id,
    state: order.state,
    runtime: {
        from: order.runtime.from?.toISOString(),
        to: order.runtime.to?.toISOString(),
    },
    created_at: order.createdAt.toISOString(),
    billing_receiver: order.billingReceiverId
})*/

const toDTO = order => {
    const dto = {}

    if (order?.voucher) dto.voucher_id = order?.voucher.voucher_id
    if (order?.billingReceiverId) dto.billing_receiver_id = order?.billingReceiverId
    if (order?.applicationContactId) dto.application_contact_id = order?.applicationContactId
    if (order?.pits) dto.pits = order?.pits
    if (order?.publications)
        dto.media_id_to_start_date = order?.publications.reduce((result, publication) => {
            const startDate = new Date(publication.runtime.from)
            return {
                ...result,
                [publication.medium_id]: startDate.toISOString(),
            }
        }, {})

    return dto
}

const OrderExtension = order => ({
    toDTO: () => toDTO(order),
    isNew: order.state === Order.STATE_NEW,
    isActive: order.state === Order.STATE_ACTIVE,
    isInactive: order.state === Order.STATE_INACTIVE,
})

export const Order = BusinessObject(OrderDescriptor).extend(OrderExtension)
Order.fromDTO = fromDTO

Order.STATE_NEW = 'new'
Order.STATE_ACTIVE = 'active'
Order.STATE_INACTIVE = 'inactive'
Order.STATE_DRAFT = 'draft'
