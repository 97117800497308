import { useContext } from 'react'
import { useViewModel } from '@lib/view_context/view-model'
import { LocationContext } from '@prospective/process-router-react'
import { Tooltip, TreeSelect, theme } from 'antd'
import { InfoIcon } from '@assets/icons/icons'
import { OrganizationHierarchy } from '@utils/organization_hierarchy.js'
import { HierarchyContext } from '@modules/hierarchy/hierarchy.js'
import { UserDetails } from '@modules/user/user.js'

const disabledRoutes = [
    'settings/customers',
    'settings/image-library/upload',
    '/profiles/manage',
    'order',
    'fallbackRoute',
    'settings/job-board/customize',
    'mediaList/preferences/manage',
    'settings/imageLibraryUpload',
]

const CompanyHierarchySelector = ({ styles = {}, disabled = false, onChange }) => {
    const viewModel = useViewModel(HierarchyContext)
    const isAdminUser = UserDetails?.state?.value?.companyAdmin
    const userLevel = isAdminUser ? 2 : 1

    const processTreeDictionary = (nodes = []) => {
        const stack = nodes.map(node => ({ node, level: node.level }))

        while (stack.length > 0) {
            const { node, level } = stack.pop()
            node.selectable = level >= userLevel
            if (node.children) stack.push(...node.children.map(child => ({ node: child, level: level + 1 })))
        }
        return nodes
    }

    return (
        <div className="company-selector" style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
            <TreeSelect
                showSearch
                maxTagCount="responsive"
                multiple={false}
                style={{ width: '100%', ...styles }}
                loading={viewModel.hierarchy.isLoading}
                value={viewModel.hierarchy.value}
                placeholder={viewModel.hierarchy.placeholder}
                disabled={viewModel.hierarchy.isLoading || disabled}
                treeData={processTreeDictionary(viewModel.hierarchy.dictionary)}
                data-test-key="hierarchySelect"
                filterTreeNode={(value, option) => option.label?.toLowerCase().indexOf(value.toLowerCase()) > -1}
                onChange={id => {
                    const node = OrganizationHierarchy(viewModel.hierarchy.dictionary).findById(id)
                    viewModel.hierarchy.setValue(node)
                    if (onChange) onChange(node)
                }}
            />
            {disabled ? (
                <div className="company-selector__wrap-item">
                    <Tooltip title={viewModel.hierarchy.description} position="bottom">
                        <InfoIcon />
                    </Tooltip>
                </div>
            ) : null}
        </div>
    )
}

const Header = () => {
    const { token } = theme.useToken()
    const location = useContext(LocationContext)
    const disabled = disabledRoutes.some(route => location?.key.includes(route))
    const isAdminUser = UserDetails?.state?.value?.companyAdmin

    const styles = {
        padding: '10px 0',
        display: 'grid',
        placeItems: 'center',
        backgroundColor: token.jbColorBgSecondary,
    }

    if(!isAdminUser) return null

    return (
        <div style={styles}>
            <CompanyHierarchySelector styles={{ width: 500 }} disabled={disabled} />
        </div>
    )
}

CompanyHierarchySelector.Header = Header
export default CompanyHierarchySelector
