import React, { createContext, useContext, useState, useEffect } from 'react'

export const VisibleBodyHeightContext = createContext()

export const useVisibleBodyHeight = () => {
    return useContext(VisibleBodyHeightContext)
}

export const VisibleBodyHeightProvider = ({ children }) => {
    const [visibleBodyHeight, setVisibleBodyHeight] = useState(window.innerHeight)

    useEffect(() => {
        const updateHeight = () => {
            const header = document.querySelector('.app-header')
            const footer = document.querySelector('.app-footer')

            const headerHeight = header?.offsetHeight || 0
            const footerHeight = footer?.offsetHeight || 0
            const viewportHeight = window.innerHeight
            const result = viewportHeight - headerHeight - footerHeight
            setVisibleBodyHeight(result)
        }

        updateHeight()

        const observer = new ResizeObserver(updateHeight)
        const header = document.querySelector('.app-header')
        const footer = document.querySelector('.app-footer')

        if (header) observer.observe(header)
        if (footer) observer.observe(footer)

        window.addEventListener('resize', updateHeight)

        return () => {
            observer.disconnect()
            window.removeEventListener('resize', updateHeight)
        }
    }, [])

    return <VisibleBodyHeightContext.Provider value={visibleBodyHeight}>{children}</VisibleBodyHeightContext.Provider>
}
