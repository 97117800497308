import { Authorization } from '@modules/authorization/authorization.js'
import {
    disableTopLevelOrganisationNodes,
    getLowestLevelNodes,
    getNodesByIds,
    getSubTree,
    includeChildNodes,
    reduceToNodes,
} from '@modules/dictionaries/organization-hierarchy.js'
import { haveSameElements, REFERENCE_COMPARE_FUNCTION } from '@prospective/pms-js-utils'
import { ProAnalyticsFiltersInterface } from '@views/pro_analytics/pro_analytics_filters.interface.js'
import { CATEGORIES, Logger } from '@lib/../../app/modules/logging/logger.js'
import { Localization } from '@lib/i18n/localization.js'
import { STATUS_PENDING } from '@utils/request_statuses'
import { stateOf, useCallback, useEffect, useMemo, useMemoLast, useState } from '@prospective/process-router'
import { PluginManager } from '@modules/plugins/plugin_manager.js'
import { ArrayReducers } from '@utils/array_utils.js'
import { useRemoteDataStream } from '@utils/process_hooks.js'
import { ProAnalyticsService } from '@views/pro_analytics/pro_analytics.service.js'
import { ModuleController } from '@modules/module_controller.js'

const logger = Logger('ProAnalyticsOrganizationFilter', CATEGORIES.MAIN)

export const ProAnalyticsOrganizationFilter = ({ params, setParams }) => {
    const { locale } = stateOf(Localization)
    const { features } = stateOf(PluginManager)
    const companyCurrency = features?.currency || 'CHF'

    const [isOrganizationStructureFilterValid, setIsOrganizationStructureFilterValid] = useState()
    const [recentlyUsedOrganizationNodes, setRecentlyUsedOrganizationNodes] = useState([])
    const [selectedOrganizationNodeIds, setSelectedOrganizationNodeIds] = useState([])
    const [selectedOrganizationNodes, setSelectedOrganizationNodes] = useState([])
    const [currency, setCurrency] = useState('CHF')
    const [organizationStructure, setOrganizationStructure] = useState()
    const [getOrganizationStructure, getOrganizationStructureState] = useRemoteDataStream(
        ProAnalyticsService.getOrganizationStructure
    )
    const [getUserDetails] = useRemoteDataStream(ModuleController.getUserDetails)

    /**
     * Disables h1 and h2 nodes. Disables nodes which use different currency then already selected ones.
     * @param organisationStructure
     * @param organisationStructureFilter
     * @return {*} organisationStructure - new organisation structure.
     */
    const getOrganisationStructureDictionary = useMemoLast(organizationStructure =>
        disableTopLevelOrganisationNodes(organizationStructure)
    )

    const setOrganizationNodes = useCallback(nodeIds => {
        const nodes = getNodesByIds(nodeIds, organizationStructure)
        const filteredIds = nodes.map(node => node.id)

        if (Authorization.isAdminUser) {
            const lowestLevelNode = getLowestLevelNodes(filteredIds)
            const selectedNode = lowestLevelNode.length ? lowestLevelNode[0] : undefined

            try {
                if (selectedNode) {
                    const recentlyUsedOrganisation = localStorage.getItem(
                        'jb3.proAnalytics.recentlyUsedOrganisationNodes'
                    )
                    let recentlyUsedOrganisationNodeIds = recentlyUsedOrganisation
                        ? JSON.parse(recentlyUsedOrganisation)
                        : []
                    if (!recentlyUsedOrganisationNodeIds.includes(selectedNode.id))
                        recentlyUsedOrganisationNodeIds.unshift(selectedNode.id)
                    if (recentlyUsedOrganisationNodeIds.length > 5)
                        recentlyUsedOrganisationNodeIds = recentlyUsedOrganisationNodeIds.slice(0, 5)
                    if (selectedNode)
                        localStorage.setItem(
                            'jb3.proAnalytics.recentlyUsedOrganisationNodes',
                            JSON.stringify(recentlyUsedOrganisationNodeIds)
                        )
                    const recentlyUsedOrganizationNodes = getNodesByIds(
                        recentlyUsedOrganisationNodeIds,
                        organizationStructure
                    )
                    setRecentlyUsedOrganizationNodes(recentlyUsedOrganizationNodes)
                }
            } catch (e) {
                logger.warn.withError(e, 'Could not read or write from/to locale storage')
            }
        }
        setParams({ organizationStructureFilter: nodeIds })
    })

    const getRecentlyUsedOrganizationNodes = organizationStructure => {
        let recentlyUsedOrganisationNodes = []
        try {
            const recentlyUsedOrganisation = localStorage.getItem('jb3.proAnalytics.recentlyUsedOrganisationNodes')
            const recentlyUsedOrganisationIds = recentlyUsedOrganisation ? JSON.parse(recentlyUsedOrganisation) : []
            recentlyUsedOrganisationNodes = getNodesByIds(recentlyUsedOrganisationIds, organizationStructure)
        } catch (e) {
            logger.warn.withError(e, 'Could not read from locale storage')
        }
        return recentlyUsedOrganisationNodes
    }

    const onRecentlyUsedOrganisationClick = useCallback(node => {
        const subTreeIds = getSubTree(node.id, organizationStructure).map(node => node.id)
        setOrganizationNodes(subTreeIds)
    })

    const filterNodesByAvailableNodes = useMemoLast((nodeIds, organizationStructure) =>
        getNodesByIds(nodeIds, organizationStructure).map(node => node.id)
    )

    const viewUpdate = useCallback(fields => {
        fields.organizationStructure.value = organizationStructure
        fields.organizationStructureFilter.dictionary = getOrganisationStructureDictionary(organizationStructure)
        fields.organizationStructureFilter.value = filterNodesByAvailableNodes(
            params.organizationStructureFilter,
            organizationStructure
        )
        fields.organizationStructureFilter.onChange = setOrganizationNodes
        fields.recentlyUsedOrganisationNodes.value = recentlyUsedOrganizationNodes
        fields.recentlyUsedOrganisationNodes.onChange = onRecentlyUsedOrganisationClick
        fields.dashboardMessage.value =
            isOrganizationStructureFilterValid || fields.dictionariesStatus.status === STATUS_PENDING
                ? undefined
                : locale('proAnalytics.selectOrganisationNodeRequest')
    })

    const getSelectedOrganizationNodes = useMemoLast(
        (organizationStructureFilter, organizationStructure, companyAdmin) => {
            const selectedOrganizationNodes = ProAnalyticsFiltersInterface({
                organizationStructureFilter,
            }).getOrganizationStructureIds(organizationStructure)
            return selectedOrganizationNodes.length
                ? selectedOrganizationNodes
                : companyAdmin
                  ? []
                  : organizationStructure.map(node => includeChildNodes([node.id], organizationStructure)).flat()
        }
    )

    const getOrganizationNodesByIds = useMemo(
        (selectedOrganizationNodes, organizationStructure) =>
            getNodesByIds(selectedOrganizationNodes, organizationStructure),
        [haveSameElements, REFERENCE_COMPARE_FUNCTION]
    )

    const getCurrencyForSelectedNodes = useMemo(selectedOrganizationNodes => {
        const nodesByLevel = selectedOrganizationNodes.reduce((result, node) => {
            if (!result[node.level]) result[node.level] = []
            result[node.level].push(node)
            return result
        }, {})
        const highestLevel = Math.min(...Object.keys(nodesByLevel))
        if (isNaN(highestLevel) || !isFinite(highestLevel)) return companyCurrency

        const currencies = nodesByLevel[highestLevel]
            .map(node => node.currency)
            .reduce(ArrayReducers.duplicatesReducer, [])

        return currencies.length !== 1 ? 'CHF' : currencies.at(0)
    })

    useEffect(() => {
        const recentlyUsedOrganizationNodes = getRecentlyUsedOrganizationNodes(organizationStructure)
        if (organizationStructure) setRecentlyUsedOrganizationNodes(recentlyUsedOrganizationNodes)
    }, [organizationStructure])

    const update = useCallback(async () => {
        const organizationStructure = await getOrganizationStructure()
        setOrganizationStructure(organizationStructure)
        const userDetails = await getUserDetails()
        const nextSelectedOrganizationNodes = getSelectedOrganizationNodes(
            params.organizationStructureFilter,
            organizationStructure,
            userDetails.companyAdmin
        )
        const organisationStructureFilterValid = !!nextSelectedOrganizationNodes.length
        setIsOrganizationStructureFilterValid(organisationStructureFilterValid)
        const nodes = getOrganizationNodesByIds(nextSelectedOrganizationNodes, organizationStructure)
        const currency = getCurrencyForSelectedNodes(nodes)
        setSelectedOrganizationNodes(nodes)
        setSelectedOrganizationNodeIds(nextSelectedOrganizationNodes)
        setCurrency(currency)

        return {
            selectedOrganizationNodes: nextSelectedOrganizationNodes,
            isOrganisationStructureFilterValid: organisationStructureFilterValid,
            currency,
        }
    })

    return { update, viewUpdate, selectedOrganizationNodes, currency, setSelectedOrganizationNodeIds }
}
