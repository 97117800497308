import { Localization } from '@lib/i18n/localization'
import { DatePickerDictionary } from '@components/elements/datepicker/datepicker'
import { View } from '@lib/view_context/view-context.js'

const getDefaultDateRange = () => {
    const today = new Date()
    return {
        from: new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 0, 0),
        to: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0),
    }
}

export const ProAnalyticsReportMetadata = locale => {
    return {
        organizationStructure: {},
        pmsFilters: {},
        title: {
            label: locale('proAnalytics.title'),
        },
        recentlyUsed: {
            label: 'Recently used',
        },
        reports: {
            label: 'All reports',
        },
        reportsStatus: {
            label: locale('status'),
        },
        queriedReports: {
            label: 'Queried reports',
        },
        queriedReportsStatus: {
            label: locale('status'),
        },
        noReportId: {
            label: 'No report ID',
        },
        reportsLink: {
            actions: ['onTrigger'],
        },
        reportXls: {
            actions: ['onTrigger'],
        },
        reportXlsLoading: {
            label: 'Download Report XLS Loading',
        },
        currentReport: {
            label: 'Current report',
        },
        customerConfigStatus: {
            label: locale('status'),
        },
        dashboardMessage: {
            label: locale('information'),
        },
        recentlyUsedOrganisationNodes: {
            label: locale('recentlyUsed'),
            default: [],
        },
        search: {
            label: locale('proAnalytics.search'),
        },
        cancelSearch: {
            actions: ['onTrigger'],
        },
        clearSearch: {
            label: locale('clear'),
            actions: ['onTrigger'],
        },
        dictionariesStatus: {
            label: locale('status'),
        },
        textFilter: {
            label: locale('proAnalytics.search'),
        },
        searchSuggestions: {
            dictionary: [],
            isLoading: false,
        },
        searchSuggestionsStatus: {
            label: locale('status'),
        },
        activeSearchSuggestions: {
            dictionary: {},
        },
        organizationStructureFilter: {
            label: locale('organizationStructure'),
        },
        recruiterFilter: {
            label: locale('recruiters'),
            dictionary: [],
            getDictionary: undefined,
            isLoading: false,
        },
        recruitersDictionaryStatus: {
            label: locale('status'),
        },
        recruiterKeyword: {
            label: locale('recruiters'),
        },
        mediaFilter: {
            label: locale('media'),
            dictionary: [],
        },
        fieldOfActivityFilter: {
            label: locale('fieldOfActivity'),
            dictionary: [],
        },
        industryFilter: {
            label: locale('industry'),
            dictionary: [],
        },
        atsFilter: {
            label: locale('atsId'),
            dictionary: [],
        },
        internalTitle: {
            label: locale('jobAd.internalTitle'),
        },
        externalTitle: {
            label: locale('jobAd.externalTitle'),
        },
        dateRangeFilter: {
            label: locale('timePeriod'),
            get default() {
                return getDefaultDateRange()
            },
            dictionary: DatePickerDictionary(locale),
        },
        resetFilters: {
            label: locale('proAnalytics.resetFilters'),
            actions: ['onTrigger'],
        },
        toggleMoreFilters: {
            label: locale('proAnalytics.lessFilters'),
        },
        backAction: {
            actions: ['onTrigger'],
        },
    }
}

export const ProAnalyticsReportContext = View.observe(Localization).update(localization =>
    ProAnalyticsReportMetadata(localization.locale)
)
