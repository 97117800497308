import { UserSession } from '@login/user-session.js'
import { Plugins } from '@plugins/plugins.js'
import { Dictionaries } from '@modules/dictionaries/dictionaries.js'
import { FeatureToggles } from '@utils/feature_toggle.jsx'
import { UserPreferences } from '@modules/user_preferences/user_preferences.js'
import { HierarchySelection } from '@modules/hierarchy/hierarchy.js'
import {
    Await,
    MapParameters,
    Memoized,
    MemoizedLast,
    Observer,
    SelfAbortable,
    Shared,
    Stream,
} from '@prospective/streamliner'
import { RemoteData, Retryable } from '@lib/remote_data/remote-data.js'
import { Authorization } from '@modules/authorization/authorization.js'
import { loadUserDetails } from '@modules/user/user.js'
import { StreamState } from '@utils/process_hooks.js'
import { Permissions } from '@modules/authorization/permissions.js'

const userDetailsMemoizer = MemoizedLast.getMemoizer()
const dictionariesMemoizer = MemoizedLast.getMemoizer()

const [pluginsState, updatePluginsState] = StreamState(RemoteData.pending())
const [loginState, updateLoginState] = StreamState(RemoteData.pending())
const [userPreferenceState, updateUserPreferenceState] = StreamState(RemoteData.pending())
const [userDetailsState, updateUserDetailsState] = StreamState(RemoteData.pending())
const [dictionariesState, updateDictionariesState] = StreamState(RemoteData.pending())
const [hierarchyNodeState, updateHierarchyNodeState] = StreamState(RemoteData.pending())
const [permissionsState, updatePermissionsState] = StreamState(RemoteData.pending())
const [proAnalyticsPermissionsState, updateProAnalyticsPermissionsState] = StreamState(RemoteData.pending())

Plugins.subscribe(() => updateHierarchyNodeState(RemoteData.pending()))
HierarchySelection.subscribe(() => updatePermissionsState(RemoteData.pending()))

const UseMigratedFlag = originalStream =>
    Stream(async function* (...args) {
        const stream = originalStream(...args)
        yield* stream
        const result = await stream
        const permissions = result.value
        const user = await getUserDetails().then(state => state.value)
        if (user.jb3_migrated || user.isAdminUser) return RemoteData.success().setValue(permissions)
        return RemoteData.success().setValue(
            Permissions(permissions).map((permissions, path) => {
                if (path.startsWith('settings.profiles') || path.startsWith('proAnalytics') || path === 'application')
                    return permissions
                return {
                    read: false,
                    write: false,
                    delete: false,
                    execute: false,
                }
            })
        )
    })

const getPlugins = Plugins.load
    .compose(Shared)
    .compose(SelfAbortable)
    .compose(Retryable)
    .compose(Observer(updatePluginsState))
    .compose(MemoizedLast)
    // .compose(MapParameters(plugins => [plugins]))
    .compose(MapParameters(plugins => (plugins ? [plugins] : [Plugins.activePlugins])))

const login = UserSession.login
    .compose(Shared)
    .compose(Await(() => getPlugins()))
    .compose(Retryable)
    .compose(Observer(updateLoginState))
    .compose(Memoized)

const getUserPreferences = UserPreferences.getUserPreferences
    .compose(Shared)
    .compose(Await(() => login()))
    .compose(Observer(updateUserPreferenceState))

const logout = UserSession.logout

const getDictionaries = Dictionaries.loadDictionaries
    .compose(Shared)
    .compose(Await(() => login()))
    .compose(Observer(updateDictionariesState))
    .compose(Retryable)
    .compose(dictionariesMemoizer)
    .compose(MapParameters(() => [FeatureToggles]))

const getUserDetails = loadUserDetails
    .compose(Shared)
    .compose(Await(login))
    .compose(Retryable)
    .compose(Observer(updateUserDetailsState))
    .compose(userDetailsMemoizer)

const getHierarchyNode = HierarchySelection.selectHierarchy
    .compose(Shared)
    .compose(MemoizedLast)
    .compose(
        MapParameters(async () => [
            await getUserDetails().then(state => state.value),
            await getDictionaries().then(state => state.value?.organizationStructure),
            HierarchySelection.activeHierarchyId,
        ])
    )
    .compose(Observer(updateHierarchyNodeState))

const getPermissions = Authorization.loadPermissions
    .compose(Observer(updatePermissionsState))
    .compose(SelfAbortable)
    .compose(Retryable)
    .compose(
        Observer(state => {
            if (state.isSuccessful) Authorization.permissions = state.value
        })
    )
    .compose(MemoizedLast)
    .compose(stream =>
        Stream(async function* () {
            const hierarchyNodeStream = getHierarchyNode()
            const state = await hierarchyNodeStream.nextValue
            if (state.isPending) yield RemoteData.queued().setMessage(state.message)

            const hierarchyNodeId = await hierarchyNodeStream.then(state => state.value?.id)
            const originalStream = stream(hierarchyNodeId, FeatureToggles.features)
            yield* originalStream
            return await originalStream
        })
    )
    .compose(Shared)

const getProAnalyticsPermissions = Authorization.loadProAnalyticsPermissions
    .compose(Observer(updateProAnalyticsPermissionsState))
    .compose(SelfAbortable)
    .compose(Retryable)
    .compose(Shared)
    .compose(
        Observer(state => {
            if (state.isSuccessful) Authorization.proAnalyticsPermissions = state.value
        })
    )
    .compose(MemoizedLast)

export const ModuleController = {
    HierarchySelection,
    getPlugins,
    login,
    logout,
    getUserPreferences,
    getDictionaries,
    getUserDetails,
    getHierarchyNode,
    getPermissions,
    getProAnalyticsPermissions,
    pluginsState,
    loginState,
    userDetailsState,
    dictionariesState,
    hierarchyNodeState,
    permissionsState,
    proAnalyticsPermissionsState,
    userPreferenceState,
    userDetailsMemoizer,
    dictionariesMemoizer,
}
