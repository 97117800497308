import { distinct } from '@prospective/pms-js-utils'

/**
 * Recursively assigns parent IDs to child objects.
 * @param {Array<Object>} data
 * @param {number} data[]
 * @param {Array<Object>} [data[].children]
 * @returns {Array<Object>}
 */
export const addParentReference = (data = []) => {
    let stack = [...data]
    while (stack.length > 0) {
        let current = stack.pop()
        if (current.children && current.children.length > 0) {
            for (let child of current.children) {
                child.parent = current.id
                stack.push(child)
            }
        } else current.parent = current.parent || null
        if (!current.hasOwnProperty('parent')) current.parent = null
    }
    return data
}

export const disableTopLevelOrganisationNodes = (dictionary = []) =>
    dictionary.map(item => {
        const newItem = { ...item, children: disableTopLevelOrganisationNodes(item.children) }
        if (newItem.level < 3) newItem.disableCheckbox = true
        return newItem
    })

export const flatten = (hierarchy = []) =>
    hierarchy.reduce((array, item) => {
        array.push(item)
        array.push(...flatten(item.children))
        return array
    }, [])

export const filterByCurrency = nodes => {
    const currency = nodes.length ? nodes[0].currency : undefined
    return nodes.filter(node => !currency || node.currency === currency)
}

/**
 * @typedef {Object} OrganizationNode
 * @property {Array} children
 * @property {string} currency
 * @property {boolean} hasAccess
 * @property {number} id
 * @property {boolean} isActive
 * @property {number} key
 * @property {string} label
 * @property {number} level
 * @property {string} name
 * @property {number} value
 */

/**
 * Creates a copy of given organisation structure, where only these nodes are enabled, which either use given currency
 * or their currency is falsy.
 * @param hierarchy
 * @param currency
 * @return {OrganizationNode[]} new organisation structure
 */
export const enableNodesByCurrency = (hierarchy, currency) =>
    [...hierarchy].map(item => {
        const newItem = { ...item }
        if (!newItem.children || newItem.children.length === 0) {
            newItem.disableCheckbox = newItem.disableCheckbox || (newItem.currency && newItem.currency !== currency)
        } else {
            newItem.children = enableNodesByCurrency(newItem.children, currency)
            newItem.disableCheckbox =
                newItem.disableCheckbox || newItem.children.every(child => child.disableCheckbox === true)
        }
        return newItem
    })

export const disableAllOrganisationNodes = dictionary =>
    dictionary.map(item => {
        const newItem = { ...item, children: disableAllOrganisationNodes(item.children) }
        newItem.disableCheckbox = true
        return newItem
    })

export const getNodesAtLevel = (hierarchy, level = 1) => {
    const result = []
    const nodes = [...hierarchy]
    while (nodes.length) {
        const node = nodes.shift()
        if (node.level === level) result.push(node)
        if (node.level < level) nodes.push(...node.children)
    }
    return result
}

export const getSubTree = (id, hierarchy) => {
    const nodes = getNodesByIds([id], hierarchy)
    const result = []
    let descendants = [...nodes]
    while (descendants.length) {
        result.push(...descendants)
        descendants = descendants.map(node => node.children || []).flat()
    }
    return result
}

/**
 * @param ids
 * @param hierarchy
 * @return {OrganizationNode[]}
 */
export const getNodesByIds = (ids = [], hierarchy = []) => {
    const result = new Array(ids.length)
    const nodes = [...hierarchy]
    let counter = ids.length
    while (nodes.length && counter > 0) {
        const node = nodes.shift()
        const idIndex = ids.indexOf(node.id)
        if (idIndex > -1) {
            result[idIndex] = node
            counter--
        }
        nodes.push(...node.children)
    }
    return result.filter(node => node !== undefined)
}

export const getLowestLevelNodes = nodes => {
    const result = nodes.reduce(
        (accumulator, node) => {
            if (node.level < accumulator.level) return { currentNodes: [node], level: node.level }
            else if (node.level === accumulator.level) accumulator.currentNodes.push(node)
            return accumulator
        },
        { currentNodes: [], level: Number.MAX_SAFE_INTEGER }
    )
    return result.currentNodes
}

/**
 * Reduces given selection to these nodes, which child nodes are all selected
 * @param {number[]} selection
 * @param hierarchy
 * @return {*[]}
 */
export const reduceToNodes = (selection, hierarchy) => {
    const nodes = [...hierarchy]
    const result = []
    while (nodes.length) {
        const node = nodes.shift()
        const selectedChildren = node.children.filter(child => selection.includes(child.id))
        if (selectedChildren.length < node.children.length) nodes.push(...node.children)
        if (selection.includes(node.id)) result.push(node.id)
    }
    return result
}

export const includeChildNodes = (selection, hierarchy) => {
    const nodes = [...hierarchy]
    const result = [...selection]
    while (nodes.length) {
        const node = nodes.shift()
        if (result.includes(node.id)) result.push(...node.children.map(child => child.id))
        nodes.push(...node.children)
    }
    return distinct(result)
}
