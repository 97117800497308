import { useContext } from 'react'
import { Alert } from 'antd'
import { LocaleContext } from '@lib/i18n/localization_provider.jsx'

export default function PermissionsCheck({ className = '', isAccessGranted, message, description, ...props }) {
    const { locale } = useContext(LocaleContext)
    return isAccessGranted ? (
        props.children
    ) : (
        <div className={className} {...props} >
            <Alert message={message || locale('insufficientPermissions')} showIcon description={description} type="error" />
        </div>
    )
}
